import jQuery from 'jquery';
import 'bootstrap';
import AOS from 'aos'

AOS.init({
  once: true
});

window.jQuery = window.$ = jQuery;
require('magnific-popup');

// var bannerSlider = tns({
//   container: '.banner-slider',
//   items: 1,
//   slideBy: 'page',
//   autoplay: true,
//   autoplayButtonOutput: false,
//   nav: false,
//   controls: false,
//   speed: 1000 
// });

// var servicesSlider = tns({
//   container: ".servicesSlider",
//   controlsContainer: "#customize-controls",
//   items: 3,
//   gutter: 30,  
//   swipeAngle: false,
//   speed: 500,
//   loop: false,
//   autoplayButtonOutput: false,
//   controls: true,
//   arrowKeys: true,
//   autoHeight: false,
//   responsive: {
//     375: {
//       edgePadding: 20,
//       gutter: 50,
//       controls: false,
//       items: 1
//     },
//     700: {
//       gutter: 30,
//       items: 2
//     },
//     900: {
//       controls: true,
//       items: 3
      
//     }
//   }

//   }
// )

// $(function() {
//   var header = $('header');

//   $(window).on('scroll', function() {
//     var scrollPosition = $(this).scrollTop();

//     if (scrollPosition > 80) {
//       header.addClass('fixed-top').slideDown(333); // Slide down with a duration of approximately 1/3 of a second (333 milliseconds)
//     } else {
//       header.slideUp(333, function() { // Slide up with a duration of approximately 1/3 of a second (333 milliseconds)
//         header.removeClass('fixed-top');
//       });
//     }
//   });
// });

// $(function() {
//   var header = $('header');
//   var windowHeight = $(window).height();
//   var documentHeight = $(document).height();

//   $(window).on('scroll', function() {
//     var scrollPosition = $(this).scrollTop();

//     if (scrollPosition + windowHeight >= documentHeight - 100) {
//       header.removeClass('fixed-top');
//     } else if (scrollPosition > 100) {
//       header.addClass('fixed-top');
//     } else {
//       header.removeClass('fixed-top');
//     }
//   });

//   // Smooth scroll animation
//   header.css('transition', 'top 2.5s ease-in-out');
// });






$(document).ready(function() {
  $('.navbar-toggler').click(function() {
    var expanded = $(this).attr('aria-expanded') === 'false' ? 'true' : 'false';
    $(this).attr('aria-expanded', expanded);
  });
});


